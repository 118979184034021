import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DashboardIndex',
    component: () => import('../views/dashboard/dashboard.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/login',
    name: 'DashboradLogin',
    component: () => import('../views/dashboard/login.vue'),
    meta: {
      title: 'login...'
    }
  },
  {
    path: '/dashboard',
    name: 'DashboardIndex',
    component: () => import('../views/dashboard/dashboard.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/detail',
    name: 'DashboardDetail',
    component: () => import('../views/dashboard/detail.vue'),
    meta: {
      title: '问题提交确认'
    }
  },
  {
    path: '/polling',
    name: 'DashboardPolling',
    component: () => import('../views/dashboard/polling.vue'),
    meta: {
      title: '巡检问题登记'
    }
  },
  {
    path: '/previewImg',
    name: 'DashboardPreview',
    component: () => import('../views/dashboard/previewImg.vue'),
    meta: {
      title: '预览图片'
    }
  },
  {
    path: '/submitSuccess',
    name: 'DashboardSubmit',
    component: () => import('../views/dashboard/submitSuccess.vue'),
    meta: {
      title: '提交成功'
    }
  },
  {
    path: '/approve',
    name: 'DashboardApprove',
    component: () => import('../views/dashboard/approve.vue'),
    meta: {
      title: '整改结果审批'
    }
  },
  {
    path: '/register',
    name: 'DashboardRegister',
    component: () => import('../views/dashboard/register.vue'),
    meta: {
      title: ''
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
